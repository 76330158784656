import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { initiateTempToken, isTokenValid } from "./api/utils";
import Footer from "./layout/footer/Footer";
import HeaderHandler from "./layout/header/HeaderHandler";
import AllRoutes from "./routes";
import "./var.module.css";
import env from "react-dotenv";
import ReactPixel from "./utils/pixel/reactPixel";
import ReactGA from "react-ga4";
import { validateUser } from "./app/auth/Auth";
import WaitingLoader from "./components/general/loader/WaitingLoader";
import { initializeCart } from "./app/cart/Cart";
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const load = useSelector((state) => state.auth.isLoading);
    const {authenticated} = useSelector((state) => state.auth);
    
    useEffect(() => {
        ReactPixel.init(env?.PIXEL_KEY);
        ReactGA.initialize("G-B4WNLQYCGM");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactPixel.pageView();
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [location]);

    useEffect(() => {
        const isAuth = isTokenValid();
        if (isAuth.isValide) {
            dispatch(validateUser({
                token: isAuth.token,
                user: isAuth.user,
                exp: isAuth.expTime
            }))
        }
         else {
            initiateTempToken();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(initializeCart())
        // eslint-disable-next-line
    }, [authenticated])

    return (
        <GoogleOAuthProvider clientId='487988760-uka6t680fihhl0d1cc2nnq5vl6cg914c.apps.googleusercontent.com'>
            {
                (location.pathname !== "/hyper/regsiter" && location.pathname !== "/hyper/survey" && location.pathname !== '/activities/halloween' && location.pathname !== "/hyper/hypersurvey") &&
                <HeaderHandler />
            }
            {
                load
                ?
                <WaitingLoader open={load} />
                :
                <div className={(location.pathname.includes('/menu') || location.pathname === "/hyper/regsiter" || location.pathname === "/hyper/survey" || location.pathname === "/hyper/hypersurvey") ? '' : 'bodyWrapper'}>
                    <AllRoutes />
                </div>
            }
            {
            (location.pathname.includes('/menu') || location.pathname === "/hyper/regsiter" || location.pathname === "/hyper/survey" || location.pathname === "/activities/halloween" || location.pathname === "/hyper/hypersurvey") ? '' : <Footer />
            }
        </GoogleOAuthProvider>
    );
}

export default App;