import React, { useEffect, useState } from 'react';
// import { Stepper, Step, StepLabel, Button, Typography, Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Icons
//Excellent
// import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
// // Good
// import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
// // Medium
// import SentimentNeutralRoundedIcon from '@mui/icons-material/SentimentNeutralRounded';
// // Poor
// import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
// // Very Bad
// import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import Lottie from 'lottie-react';
import animationData from './SuccessAnimation.json';
import FetchData from '../../api/FetchData';
import shortid from 'shortid';
import { Fade, TextField } from '@mui/material';

export default function HyperSurvery() {

    const [reaction, setReaction] = useState('positive');
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([1, 2]);
    const [comment, setComment] = useState(false);
    const [commentData, setCommentData] = useState('');
    const [surveyButton, setSurveryButton] = useState([]);
    const [currentId, setCurrentId] = useState(shortid.generate());
    // Animation Start
    const [isComplete, setIsComplete] = useState(false);
    const [surveyData, setSurveryData] = useState({
        id: currentId,
        first: '',
        second: '',
        third: '',
        fourth: ''
    });
    const [loading, setLoading] = useState(false);
    const handleComplete = () => {
        setReaction(null);
        setIsComplete(false);
        setLoading(false);
    };
    const handleToggle = (value) => {
        console.log(value);
        setSurveryButton((prevState) => {
            // Check if the question is already in the array and toggle based only on the 'question' field
            if (prevState.some((val) => val.question === value.question)) {
                // Remove the value if the question already exists
                return prevState.filter((val) => val.question !== value.question);
            } else {
                // Add the value if the question does not exist
                return [...prevState, value];
            }
        });
    };

    const date = new Date();
    const handleNext = (data, step, quest, reactionType) => {
        setLoading(true);
        setReaction(reactionType);
        if (activeStep < steps.length - 1) {
            setSurveryData({ ...surveyData, [step]: data })
            FetchData.create('/data/submitSurvey', {
                id: currentId,
                data: JSON.stringify(data),
                from: 'hyper'
            }).then(res => {
                setActiveStep(activeStep + 1);
                setLoading(false);
            })
        } else {
            FetchData.create('/data/submitSurvey', {
                id: currentId,
                data: JSON.stringify(data)
            }).then(res => {
                setLoading(true);
                setIsComplete(true);
            })
                .finally(() => {
                    reset();
                })
        }
    };

    function reset() {
        setTimeout(() => {
            setSteps([1, 2]);
            setReaction(null);
            setSurveryButton([]);
            setCurrentId(shortid.generate());
            setActiveStep(0);
        }, [2000])

    }

    function checkForRefresh(setActiveStep) {
        const intervalId = setInterval(() => {
            setSteps([1, 2]);
            setActiveStep(0);
            setReaction(null);
            setSurveryButton([]);
        }, 30000);
        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }

    useEffect(() => {
        if (activeStep > 0) {
            if (surveyButton.length === 0) {
                if (comment === false) {
                    const cleanup = checkForRefresh(setActiveStep);
                    return cleanup;
                }
                const cleanup = checkForRefresh(setActiveStep);
                return cleanup;
            } else if (comment === false) {
                if (surveyButton.length === 0) {
                    const cleanup = checkForRefresh(setActiveStep);
                    return cleanup;
                }
            } else {
                return;
            }
        }
    }, [activeStep, comment, surveyButton]);

    const renderContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} textAlign={'center'} fontSize={'35px'}>Si ishte eksperienca juaj në HyperActive sot?</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '60px', gridGap: '50px' }}>
                            {/* Negative */}
                            <Button disableRipple disableFocusRipple disableElevation sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext(
                                {
                                    step: 1,
                                    data: 'veryBad',
                                    quest: 'Si ishte eksperienca juaj në HyperActive sot?',
                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                                },
                                'first',
                                'Si ishte eksperienca juaj në HyperActive sot?',
                                'negative'
                            )}>
                                <Box>
                                    <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-02.png' sx={{ width: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Button>

                            <Button disableRipple disableFocusRipple disableElevation sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext(
                                {
                                    step: 1,
                                    data: 'poor',
                                    quest: 'Si ishte eksperienca juaj në HyperActive sot?',
                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                                },
                                'first',
                                'Si ishte eksperienca juaj në HyperActive sot?',
                                'negative'
                            )}>
                                <Box>
                                    <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-03.png' sx={{ width: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Button>

                            <Button disableRipple disableFocusRipple disableElevation sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext(
                                {
                                    step: 1,
                                    data: 'fine',
                                    quest: 'Si ishte eksperienca juaj në HyperActive sot?',
                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                                },
                                'first',
                                'Si ishte eksperienca juaj në HyperActive sot?',
                                'negative'
                            )}>
                                <Box>
                                    <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-04.png' sx={{ width: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Button>


                            {/* Positive */}
                            <Button disableRipple disableFocusRipple disableElevation sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext(
                                {
                                    step: 1,
                                    data: 'good',
                                    quest: 'Si ishte eksperienca juaj në HyperActive sot?',
                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                                },
                                'first',
                                'Si ishte eksperienca juaj në HyperActive sot?',
                                'positive'
                            )}>
                                <Box>
                                    <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-05.png' sx={{ width: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Button>
                            <Button disableRipple disableFocusRipple disableElevation sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext(
                                {
                                    step: 1,
                                    data: 'excellent',
                                    quest: 'Si ishte eksperienca juaj në HyperActive sot?',
                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                                }, 'first', 'Si ishte eksperienca juaj në HyperActive sot?', 'positive'
                            )}>
                                <Box>
                                    <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-06.png' sx={{ width: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Button>



                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <Box height={255}>
                        {
                            reaction === 'positive'
                                ?
                                <Box>
                                    <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} color={'#00b050'}>ÇFARË NDIKOI POZITIVISHT NË EKSPERIENCËN TUAJ?</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 50px 1fr', marginTop: '50px' }}>
                                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    border: 'solid 2px #00b050',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Shërbim cilësor nga stafi') ? ' #00b050' : '#FFF',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Shërbim cilësor nga stafi',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Shërbim cilësor nga stafi
                                            </Box>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    border: 'solid 2px #00b050',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Varieteti i atraksioneve') ? ' #00b050' : '#FFF',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Varieteti i atraksioneve',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Varieteti i atraksioneve
                                            </Box>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Pastërtia dhe mirëmbajtja') ? ' #00b050' : '#FFF',
                                                    border: 'solid 2px #00b050',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Pastërtia dhe mirëmbajtja',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Pastërtia dhe mirëmbajtja
                                            </Box>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Eksperiencë unike') ? ' #00b050' : '#FFF',
                                                    border: 'solid 2px #00b050',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Eksperiencë unike',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Eksperiencë unike
                                            </Box>
                                        </Box>
                                        <Box></Box>
                                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Aktivitete për të gjitha moshat') ? ' #00b050' : '#FFF',
                                                    border: 'solid 2px #00b050',
                                                    
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Aktivitete për të gjitha moshat',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Aktivitete për të gjitha moshat
                                            </Box>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === "Ushqimi dhe shërbimi në Bana's") ? ' #00b050' : '#FFF',
                                                    border: 'solid 2px #00b050',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: "Ushqimi dhe shërbimi në Bana's",
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Ushqimi dhe shërbimi në Bana’s
                                            </Box>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    border: 'solid 2px #00b050',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Ushqimi dhe shërbimi në SportsBar') ? ' #00b050' : '#FFF',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Ushqimi dhe shërbimi në SportsBar',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}
                                            >
                                                Ushqimi dhe shërbimi në SportsBar
                                            </Box>
                                            <Box
                                                sx={{
                                                    padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    border: 'solid 2px #00b050',
                                                    color: '#000',
                                                    backgroundColor: surveyButton.find(val => val.question === 'Ofertat e mira') ? ' #00b050' : '#FFF',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Ofertat e mira',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Ofertat e mira
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '10px' }}>
                                        <Button sx={{ border: 'solid 2px #00b050', borderRadius: '40px', color: '#000',  textTransform: 'none' }} onClick={() => { setComment(true); setSteps([1, 2, 3]); setActiveStep(activeStep + 1); }}>
                                            Komenti Juaj ...
                                        </Button>
                                        <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleNext(surveyButton, 2, '', null)}>
                                            Përfundo vlerësimin
                                        </Button>
                                    </Box>
                                </Box>
                                :
                                <Box>
                                    <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} color={'#ff3737'}>ÇFARË DUHET TË PËRMIRËSOJMË?</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 50px 1fr', marginTop: '50px' }}>
                                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.find(val => val.question === 'Pritjet e gjata në atraksione');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Pritjet e gjata në atraksione',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>

                                                Pritjet e gjata në atraksione
                                            </Box>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.find(val => val.question === 'Shërbimin e stafit në atraksione');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Shërbimin e stafit në atraksione',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Shërbimin e stafit në atraksione
                                            </Box>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.find(val => val.question === 'Sigurinë në atraksione');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Sigurinë në atraksione',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Sigurinë në atraksione
                                            </Box>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.some(val => val.question === 'Volumin shumë të lartë të muzikës');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Volumin shumë të lartë të muzikës',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Volumin shumë të lartë të muzikës
                                            </Box>
                                        </Box>
                                        <Box></Box>
                                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.some(val => val.question === 'Më shumë varietete të atraksioneve');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Më shumë varietete të atraksioneve',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Më shumë varietete të atraksioneve
                                            </Box>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.some(val => val.question === 'Ushqimin në Sportsbar');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Ushqimin në Sportsbar',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Ushqimin në Sportsbar
                                            </Box>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.some(val => val.question === 'Ushqimin në Bana’s');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Ushqimin në Bana’s',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Ushqimin në Bana’s
                                            </Box>
                                            <Box
                                                sx={{
                                                    backgroundColor: () => {
                                                        const isActive = surveyButton.some(val => val.question === 'Pastërtinë dhe mirëmbajtjen');
                                                        return `${isActive ? '#ff3737' : '#FFF'}`;
                                                    },padding: '5px',
                                                    textAlign: 'center',
                                                    transition: '.2s all',
                                                    borderRadius: '40px',
                                                    color: '#000',
                                                    border: 'solid 2px #ff3737',
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => handleToggle({
                                                    question: 'Pastërtinë dhe mirëmbajtjen',
                                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                                    step: 2
                                                })}>
                                                Pastërtinë dhe mirëmbajtjen
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '10px' }}>
                                        <Button sx={{ border: 'solid 2px #ff3737', borderRadius: '40px', color: '#000',  textTransform: 'none' }} onClick={() => { setComment(true); setSteps([1, 2, 3]); setActiveStep(activeStep + 1); }}>
                                            Komenti Juaj ...
                                        </Button>
                                        <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleNext(surveyButton, 2, '', null)}>
                                            Përfundo vlerësimin
                                        </Button>
                                    </Box>
                                </Box>
                        }

                    </Box>
                );
            case 2:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} textAlign={'center'} color={reaction === 'positive' ? '#00b050' : '#ff3737'}>Komenti Juaj</Typography>
                        <TextField
                            rows={10}
                            fullWidth
                            multiline={true}
                            sx={{
                                fieldset: {
                                    border: 'none',
                                    outline: 'none'
                                },
                                marginTop: '20px',
                                input: {},
                                '& .MuiOutlinedInput-root': { outline: 'none !important' },
                                border: 'solid 2px',
                                borderColor: reaction === 'positive' ? '#00b050' : '#ff3737',
                                borderRadius: '6px'
                            }}
                            onChange={(e) => setCommentData(e.target.value)}
                        />
                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '20px' }}>
                            <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleNext(
                                [surveyButton, {
                                    userComment: commentData,
                                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                    step: 2
                                }],
                                3, '', null)}>
                                Përfundo vlerësimin
                            </Button>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };
    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
            {
                loading ?
                    <Box sx={{ height: '80%', width: '80%', marginX: 'auto' }}>
                        {
                            isComplete === true ?
                                (
                                    <Box sx={{ width: '35%', height: '35%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                        <Lottie
                                            speed={10}
                                            animationData={animationData}
                                            onLoopComplete={handleComplete}
                                            loop={isComplete}
                                        />
                                        <Typography variant="h4" textAlign={'center'}>Ju faleminderit!</Typography>
                                    </Box>
                                )
                                :
                                <Box>

                                </Box>
                        }
                    </Box>
                    :
                    <>
                        <Fade in={loading === false} timeout={1000}>
                            <Box sx={{ paddingBottom: '50px' }}>
                                <Box width={300} paddingTop={'50px'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginX: 'auto' }}>
                                    <Box component={'img'} src='https://hyper-active.com/images/HyperActive_dark_Logo.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                                </Box>
                                <Box sx={{ marginTop: '25px' }}>
                                    <Stepper activeStep={activeStep} alternativeLabel></Stepper>
                                    <Box sx={{ width: '90%', marginX: 'auto' }}>
                                        {renderContent(activeStep)}
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                    </>
            }
        </Box>
    )
}