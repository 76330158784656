import React, { useEffect, useState } from 'react';
// import { Stepper, Step, StepLabel, Button, Typography, Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Icons
//Excellent
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
// Good
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
// Medium
import SentimentNeutralRoundedIcon from '@mui/icons-material/SentimentNeutralRounded';
// Poor
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
// Very Bad
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import Lottie from 'lottie-react';
import animationData from './SuccessAnimation.json';
import FetchData from '../../api/FetchData';
import shortid from 'shortid';
import { Fade } from '@mui/material';

export default function Survey() {

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
    const [currentId, setCurrentId] = useState(shortid.generate());
    // Animation Start
    const [isComplete, setIsComplete] = useState(false);
    const [surveyData, setSurveryData] = useState({
        id: currentId,
        first: '',
        second: '',
        third: '',
        fourth: ''
    });
    const [loading, setLoading] = useState(false);
    const handleComplete = () => {
        setIsComplete(false);
        setLoading(false);
    };

    const date = new Date();

    const handleNext = (data, step, quest) => {
        setLoading(true);
        if (activeStep < steps.length - 1) {
            setSurveryData({ ...surveyData, [step]: data })
            FetchData.create('/data/submitSurvey', {
                id: currentId,
                data: JSON.stringify({
                    step: step,
                    data: data, 
                    quest: quest,
                    date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                }),
                from: 'banas'
            }).then(res => {
                setActiveStep(activeStep + 1);
                setLoading(false);
            })
        } else {
            FetchData.create('/data/submitSurvey', {
                id: currentId,
                data: JSON.stringify({step: step,
                    data: data, 
                    quest: quest})
            }).then(res => {
                setLoading(true);
                setIsComplete(true);
            })
            .finally(() => {
                reset();   
            })
        }
    };

    function reset() {
        setTimeout(() => {
            setCurrentId(shortid.generate());
            setActiveStep(0); 
        }, [2000])
        
    }

    function checkForRefresh(setActiveStep) {
        const intervalId = setInterval(() => {
            setActiveStep(0);
        }, 30000);
        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }

    useEffect(() => {
        if (activeStep > 0) {
            // Start the refresh interval
            const cleanup = checkForRefresh(setActiveStep);
            // Cleanup when the component unmounts or activeStep changes
            return cleanup;
        }
    }, [activeStep]);

    const renderContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} textAlign={'center'} fontSize={'35px'}>Si e vlerësoni cilësinë e ushqimit?</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '60px' }}>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('veryBad', 'first', 'Si e vlerësoni cilësinë e ushqimit?')}><Box><MoodBadOutlinedIcon sx={{ fill: '#bb1e2c', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#bb1e2c' }}>Shumë keq </Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('poor', 'first', 'Si e vlerësoni cilësinë e ushqimit?')}><Box><SentimentDissatisfiedOutlinedIcon sx={{ fill: '#e35060', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#e35060' }}>Keq</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('medium', 'first', 'Si e vlerësoni cilësinë e ushqimit?')}><Box><SentimentNeutralRoundedIcon sx={{ fill: '#faaf45', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#faaf45' }}>Mirë</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('good', 'first', 'Si e vlerësoni cilësinë e ushqimit?')}><Box><SentimentSatisfiedAltOutlinedIcon sx={{ fill: '#8dc542', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#8dc542' }}>Shumë mirë</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('excellent', 'first', 'Si e vlerësoni cilësinë e ushqimit?')}><Box><EmojiEmotionsOutlinedIcon sx={{ fill: '#006b3a', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#006b3a' }}>Shkëlqyeshem</Typography></Button>
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} textAlign={'center'} fontSize={'35px'}>Si e vlerësoni shërbimin nga stafi?</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '60px' }}>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('veryBad', 'second', 'Si e vlerësoni shërbimin nga stafi?')}><Box><MoodBadOutlinedIcon sx={{ fill: '#bb1e2c', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#bb1e2c' }}>Shumë keq </Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('poor', 'second', 'Si e vlerësoni shërbimin nga stafi?')}><Box><SentimentDissatisfiedOutlinedIcon sx={{ fill: '#e35060', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#e35060' }}>Keq</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('medium', 'second', 'Si e vlerësoni shërbimin nga stafi?')}><Box><SentimentNeutralRoundedIcon sx={{ fill: '#faaf45', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#faaf45' }}>Mirë</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('good', 'second', 'Si e vlerësoni shërbimin nga stafi?')}><Box><SentimentSatisfiedAltOutlinedIcon sx={{ fill: '#8dc542', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#8dc542' }}>Shumë mirë</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('excellent', 'second', 'Si e vlerësoni shërbimin nga stafi?')}><Box><EmojiEmotionsOutlinedIcon sx={{ fill: '#006b3a', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#006b3a' }}>Shkëlqyeshem</Typography></Button>
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} textAlign={'center'} fontSize={'35px'}>Si e vlerësoni higjienën?</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '60px' }}>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('veryBad', 'third', 'Si e vlerësoni higjienën?')}><Box><MoodBadOutlinedIcon sx={{ fill: '#bb1e2c', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#bb1e2c' }}>Shumë keq </Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('poor', 'third', 'Si e vlerësoni higjienën?')}><Box><SentimentDissatisfiedOutlinedIcon sx={{ fill: '#e35060', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#e35060' }}>Keq</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('medium', 'third', 'Si e vlerësoni higjienën?')}><Box><SentimentNeutralRoundedIcon sx={{ fill: '#faaf45', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#faaf45' }}>Mirë</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('good', 'third', 'Si e vlerësoni higjienën?')}><Box><SentimentSatisfiedAltOutlinedIcon sx={{ fill: '#8dc542', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#8dc542' }}>Shumë mirë</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('excellent', 'third', 'Si e vlerësoni higjienën?')}><Box><EmojiEmotionsOutlinedIcon sx={{ fill: '#006b3a', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#006b3a' }}>Shkëlqyeshem</Typography></Button>
                        </Box>
                    </Box>
                );
            case 3:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} textAlign={'center'} fontSize={'35px'}>A do të na vizitonit përsëri?</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', marginTop: '60px' }}>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('Po', 'fourth', 'A do të na vizitonit përsëri?')}><Box><EmojiEmotionsOutlinedIcon sx={{ fill: '#006b3a', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#006b3a' }}>Po</Typography></Button>
                            <Button sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }} onClick={() => handleNext('Jo', 'fourth', 'A do të na vizitonit përsëri?')}><Box><MoodBadOutlinedIcon sx={{ fill: '#bb1e2c', height: '100px', width: '100px' }} /></Box><Typography variant="h6" fontWeight={'bold'} sx={{ color: '#bb1e2c' }}>Jo</Typography></Button>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };
    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
            {
                loading ?
                    <Box sx={{ height: '80%', width: '80%', marginX: 'auto' }}>
                        {
                            isComplete === true ?
                            (
                                <Box sx={{width: '35%', height: '35%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <Lottie
                                        speed={10} 
                                        animationData={animationData}
                                        onLoopComplete={handleComplete}
                                        loop={isComplete}
                                    />
                                        <Typography variant="h4" textAlign={'center'}>Ju faleminderit!</Typography>
                                </Box>
                            )
                            :
                            <Box>
                                
                            </Box>
                        }
                    </Box>
                    :
                    <>
                        <Fade in={loading === false} timeout={1000}>
                            <Box sx={{paddingBottom: '50px'}}>
                                <Box height={300} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box component={'img'} src='https://hyper-active.com/images/Banas Logo.svg' sx={{ height: '100%', objectFit: 'cover' }} />
                                </Box>
                                <Box sx={{ marginTop: '25px' }}>
                                    <Stepper activeStep={activeStep} alternativeLabel></Stepper>
                                    <Box sx={{ width: '90%', marginX: 'auto' }}>
                                        {renderContent(activeStep)}
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                    </>
            }
        </Box>
    )
}
